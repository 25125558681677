import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/public/ComoFunciona/icone-check.webp");
;
import(/* webpackMode: "eager" */ "/app/public/ComoFunciona/icone-orcamento.webp");
;
import(/* webpackMode: "eager" */ "/app/public/ComoFunciona/icone-selecao.webp");
;
import(/* webpackMode: "eager" */ "/app/src/components/Blocks/ComoFunciona/styles.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/Blocks/Avaliacoes/styles.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Faq"] */ "/app/src/components/Blocks/Faq.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ChoseScreen"] */ "/app/src/components/Blocks/FirstBlockSegmentos/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Form"] */ "/app/src/components/Forms/Lead/Form.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/styles/card.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Swiper","Slide"] */ "/app/src/components/Swiper.tsx");
